import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeaderOneColumn from "../assets/elements/StandardHeaderOneColumn";
import Cta from "../partials/Cta";
import SEO from "../components/seo";

import GIPolypVideo from "../../static/media/gi_polyp_detection.webm";
import GIAnnotationDemo from "../../static/media/gi_annotation.webm";
import LogoCloud from "../assets/elements/LogoCloud";
import { StaticImage } from "gatsby-plugin-image";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import CaseStudyKings from "../partials/CaseStudyKings";
import { EncordGradientText } from "../utilities/DesignUtilities";
import { Script } from "gatsby";
import VideoComponent from "../components/VideoComponent";

export default function Gastroenterology({ location }) {
  return (
    <Layout location={location}>
      <LeftColours />
      <RightColours />

      <StandardHeaderOneColumn
        title={
          <>
            Accelerate Development of AI Models for{" "}
            <EncordGradientText text={"Gastroenterology Computer Vision"} />
          </>
        }
        location={location}
        pageName={"Gastroenterology Page"}
        description={
          "Encord's platform helps you improve video yield, produce better clearance-ready training data, and accelerate GI model development."
        }
        heroGraphic={
          <>
            <VideoComponent loop autoPlay muted className="rounded-lg">
              <source
                src={GIPolypVideo}
                type="video/webm"
                width="768"
                height="432"
              />
            </VideoComponent>
          </>
        }
      />

      <LogoCloud
        title={
          "Trusted by trailblazing AI teams at leading medical institutions"
        }
        pb={10}
        pbMd={10}
        numImages={5}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/ab584df2-0365-496d-a450-9df08c58fade_Memorial+Sloan+Kettering+Cancer+Center-1.svg"
            }
            alt={"MSK logo"}
            className={"h-20 w-44"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={"Build Better Machine Learning Models With Encord"}
        description={
          <>
            <p>
              Encord is the leading platform for annotating GI images and
              videos, monitoring annotator throughput and quality, and managing
              the expert review process.
            </p>
            <br />
            <p>
              Encord offers unlimited video clip length, complex ontologies, and
              AI-assisted video labeling across all types of diagnostic data,
              including capsule endoscopy, colonoscopy or endoscopy.
            </p>
          </>
        }
        features={[]}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <VideoComponent loop autoPlay muted className="rounded-lg">
            <source
              src={GIAnnotationDemo}
              type="video/webm"
              width="768"
              height="432"
            />
          </VideoComponent>
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/gi_ontologies.webp"}
            width={450}
            alt="Ontology of artefact, UCEIS score, and Mayo score for GI"
            className="rounded-lg"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/gi_quality_grid.webp"}
            width={450}
            alt="Automated quality control for GI videos"
            className="rounded-lg"
          />
        }
        headerOne={
          "Gastroenterology Segmentation and Annotation for Training Artificial Intelligence Models"
        }
        headerTwo={"Infinitely Complex Taxonomies"}
        headerThree={"One Annotation Tool For All Labeling"}
        descriptionOne={
          <>
            <p>
              Encord is the only computer vision data platform that supports the
              labeling of videos of any length. For gastroenterology, this means
              any video can be uploaded, no matter its format or length,
              ensuring your model has the best datasets for detecting cancerous
              polyps, ulcers, IBS or any other condition.
            </p>
            <br />
            <p>
              And thanks to Encord’s AI-assisted image & video annotation and
              ability to pre-label data using your own models, you can quickly
              classify your video clips, label points of interest and tag
              artefacts.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Whether you’re building a model based on UCEIS, MAYO, CANUKA or
              MMES, Encord’s taxonomy editor can help you get the data you need.
            </p>
            <br />
            <p>
              The taxonomies (ontologies) in Encord allow for multiple
              classifications and sub-classifications - as many as you need to
              get the data your gastroenterology model needs to deliver accurate
              diagnoses.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord has multiple types of video annotation types within a
              single platform, including bounding box, polygon, polyline,
              keypoint, segmentation and classification.
            </p>
            <br />
            <p>
              This means your annotators and reviewers only have to use a single
              piece of software to do their jobs. And your developers don’t have
              to try and maintain multiple in-house tools to cover each type of
              annotation task.
            </p>
          </>
        }
      />

      <section className="pt-12 pb-12 border-t border-gray-200 bg-gradient-to-b from-gray-100 to-white">
        <div className="max-w-6xl px-4 mx-auto sm:px-6">
          <CaseStudyKings externalLink={`https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8046591/`} includeCTALink />
        </div>
      </section>

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeTopBorder
        includeTopPadding
        includeHeader={false}
        title={""}
        header={""}
        description={""}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_approval.webp"}
            width={250}
            alt="Encord supports FDA clearance and CE marking processes"
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../../static/media/radiology_accuracy.webp"}
            width={550}
            alt="Encord improves the quality of your gastroenterology training data"
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../../static/media/gi_workflow.webp"}
            width={600}
            alt="Expert review quality control for gastroenterology"
          />
        }
        headerOne={"Build a Foundation for FDA and CE Regulatory Clearance"}
        headerTwo={"Deliver Cleaner Endoscopy and Colonoscopy Datasets"}
        headerThree={"Efficient Medical Grade Video Annotation"}
        descriptionOne={
          <>
            <p>
              Getting the evidence needed to get FDA or CE approval is hard and
              time consuming. But Encord offers the key features needed to make
              this process as streamlined as possible.
            </p>
            <br />
            <p>
              Put in place granular quality controls, define different sampling
              rates and set up custom review workflows for each class of label.
              And on top of that, all data with Encord is fully auditable,
              allowing you to easily show how you created the dataset your
              machine is trained on.
            </p>
          </>
        }
        descriptionTwo={
          <>
            <p>
              Encord offers customisable dashboards to help clinical operations
              teams find the errors, biases and imbalances in the training data
              their annotation teams have created and reviewed.
            </p>
            <br />
            <p>
              Combined with Encord’s ability to easily tag artefacts in the
              videos (such as mucus, glare or air bubbles), clinical operations
              teams can be sure they’re providing the best datasets to their
              machine learning teams.
            </p>
          </>
        }
        descriptionThree={
          <>
            <p>
              Encord is designed to work with your skilled gastroenterological
              annotators and reviewers, saving them time and improving their
              experience.
            </p>
            <br />
            <p>
              AI-assisted labeling makes the annotation task less of a chore and
              means annotators can accurately label more images. Annotation
              hotkeys helps to create an intuitive and efficient interface for
              your annotation team.
            </p>
          </>
        }
      />

      <Cta
        location={location}
        pageName={"Gastroenterology Page"}
        ctaText={"Accelerate AI model training in gastroenterology"}
      />
    </Layout>
  );
}

export const Head = () => (
  <>
    <Script id="g2crowd-tracking-script" defer>
      {`(function (c, p, d, u, id, i) {
  id = ''; // Optional Custom ID for user in your system
  u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
  i = document.createElement('script');
  i.type = 'application/javascript';
  i.async = true;
  i.src = u;
  d.getElementsByTagName('head')[0].appendChild(i);
}("1008306", document.location.href, document));`}
    </Script>
    <SEO
      title="AI Training Data Platform for Gastroenterology | Encord"
      description="Accelerate AI development for gastroenterology with Encord’s collaborative video annotation tools & active learning pipelines."
    />
  </>
);
